import { images } from "src/assets/images/sites";
import styled from "styled-components";
import { SiteRowProps } from "./types";

const StyledLink = styled("a")`
  background-color: #fff;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border: 1px solid #ccc;
  width: 100%;
  align-items: center;
  border-radius: 0.5rem;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;

const StyledImage = styled("img")`
  object-fit: cover;
  height: 8rem;
  width: 14rem;
`;

const StyledLi = styled("li")`
  list-style-type: square;
`;

export const SiteRow = ({
  image,
  siteUrl,
  name,
  peaks,
  bonuses,
}: SiteRowProps) => {
  return (
    <StyledLink href={siteUrl} target="_blank" rel="noreferrer">
      <StyledImage src={images[image]} alt={name} />
      <h2>{name}</h2>
      <ul>
        {peaks.map((peak) => (
          <StyledLi key={peak}>{peak}</StyledLi>
        ))}
      </ul>
      <ul>
        {bonuses.map((bonus) => (
          <StyledLi key={bonus.id}>
            {bonus.name}
            <br />
            {bonus.description}
          </StyledLi>
        ))}
      </ul>
    </StyledLink>
  );
};
