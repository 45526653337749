import { InfoBox } from "src/layouts/InfoBox";
import { MainWrapper } from "src/layouts/MainWrapper";
import contact from "src/data/pages/contact.json";

export const Contact = () => {
  const { h1, intro, title, metaDescription } = contact;

  return (
    <MainWrapper title={title} metaDescription={metaDescription}>
      <InfoBox>
        <h1>{h1}</h1>
        <p>{intro}</p>
      </InfoBox>
    </MainWrapper>
  );
};
