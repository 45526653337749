import styled from "styled-components";
import { BetRowProps } from "./types";
import { Link } from "react-router-dom";

const StyledTr = styled("tr")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  grid-gap: 1rem;
  padding: 1rem;
  width: 100%;
  background-color: #f0f0f0;
  border: 1px solid lightgrey;

  &:not(:last-of-type) {
    border-bottom: none;
  }

  &:hover {
    background-color: #e0e0e0;
  }

  h2 {
    font-size: 1rem;
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
  }
`;

const StyledTd = styled("td")`
  text-align: center;

  &:first-of-type {
    text-align: left;
  }

  &:last-of-type {
    grid-column: span 2;
  }

  @media (min-width: 768px) {
    &:last-of-type {
      grid-column: span 1;
    }
  }
`;

const StyledLink = styled("a")`
  background-color: #007bff;
  color: white;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0056b3;
  }
`;

const StyledInternalLink = styled(Link)`
  background-color: #28a745;
  color: white;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.2s;
  margin-left: 0.5rem;

  &:hover {
    background-color: #218838;
  }
`;

const StyledParagraph = styled("p")`
  margin: 0;
`;

const EventParagraph = styled(StyledParagraph)`
  font-size: 0.875rem;
  color: #6c757d;
`;

export const BetRow = ({
  bet,
  event,
  match,
  odds,
  slug,
  betUrl,
  date,
}: BetRowProps) => {
  return (
    <StyledTr>
      <StyledTd>
        <StyledParagraph>{match}</StyledParagraph>
        <EventParagraph>{event}</EventParagraph>
      </StyledTd>
      <StyledTd>{odds}</StyledTd>
      <StyledTd>{date}</StyledTd>
      <StyledTd>{bet}</StyledTd>
      <StyledTd>
        <StyledLink href={betUrl} target="_blank" rel="noreferrer">
          Spela nu
        </StyledLink>
        <StyledInternalLink to={slug}>Läs mer</StyledInternalLink>
      </StyledTd>
    </StyledTr>
  );
};
