import { InfoBox } from "src/layouts/InfoBox";
import { MainWrapper } from "src/layouts/MainWrapper";
import oddsBonuses from "src/data/pages/oddsBonuses.json";

export const OddsBonuses = () => {
  const { h1, intro, title, metaDescription } = oddsBonuses;
  return (
    <MainWrapper title={title} metaDescription={metaDescription}>
      <InfoBox>
        <h1>{h1}</h1>
        <p>{intro}</p>
      </InfoBox>
    </MainWrapper>
  );
};
