import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import header from "src/data/header.json";
import { useCallback, useEffect, useRef, useState } from "react";
import { useOutsideClick } from "src/hooks/useOutsideClick";

const StyledHeader = styled("header")`
  background-color: #005f8e;
  color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const StyledNav = styled("nav")`
  max-width: 1440px;
  padding: 1rem;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
`;

const StyledMenu = styled("menu")`
  position: fixed;
  top: 4rem;
  bottom: 0;
  z-index: 10;
  right: -100%;
  width: 80vw;
  background-color: #005f8e;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 2rem;
  padding-top: 6rem;
  gap: 2rem;
  opacity: 0;
  pointer-events: none;
  transition:
    right 0.5s,
    opacity 0.75s;

  [data-is-open="true"] & {
    right: 0;
    opacity: 1;
    pointer-events: auto;
  }

  @media (min-width: 768px) {
    position: static;
    flex-direction: row;
    justify-content: space-between;
    opacity: 1;
    pointer-events: auto;
    padding: 0;
    width: auto;
    gap: 1rem;

    @media (hover: hover) {
      li:hover {
        text-decoration: underline;
      }
    }
  }
`;

const StyledLi = styled("li")`
  font-size: 1.5rem;

  &:hover {
    text-decoration: underline;
  }

  &[data-is-active="true"] {
    text-decoration: underline;
  }

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

const StyledLogoLink = styled(Link)`
  font-size: 1.75rem;
  font-weight: bold;
  font-family: "EB Garamond", serif;
  margin: 0;
`;

const HamburgerMenu = styled("button")`
  background-color: transparent;
  border: none;
  appearance: none;
  z-index: 20;

  & > div {
    width: 2rem;
    height: 0.25rem;
    background-color: #ffffff;
    border-radius: 0.25rem;
    margin: 0.375rem 0;
    transition: all 0.5s;
  }

  [data-is-open="true"] menu ~ & {
    /* Transform hamburger menu to a cross symbol. Hide the middle "line" */
    & > div:first-child {
      transform: rotate(45deg) translate(0.45rem, 0.45rem);
    }

    & > div:nth-child(2) {
      opacity: 0;
    }

    & > div:last-child {
      transform: rotate(-45deg) translate(0.45rem, -0.45rem);
    }
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

export const Header = () => {
  const { pathname } = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const headerRef = useRef<HTMLElement>(null);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);

    // Prevent scrolling when the menu is open
    if (!isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  const resetMenuIfOpen = useCallback(() => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
      document.body.style.overflow = "auto";
    }
  }, [isMenuOpen]);

  useOutsideClick(headerRef, () => {
    resetMenuIfOpen();
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => resetMenuIfOpen, [resetMenuIfOpen, pathname]);

  return (
    <StyledHeader data-is-open={isMenuOpen} ref={headerRef}>
      <StyledNav>
        <StyledLogoLink to="/">Proffstips</StyledLogoLink>
        <StyledMenu>
          {header.map((item) => (
            <StyledLi key={item.id} data-is-active={pathname === item.url}>
              <Link to={item.url}>{item.text}</Link>
            </StyledLi>
          ))}
        </StyledMenu>
        <HamburgerMenu onClick={toggleMenu}>
          <div />
          <div />
          <div />
        </HamburgerMenu>
      </StyledNav>
    </StyledHeader>
  );
};
