import { Helmet } from "react-helmet-async";
import { HeadProps } from "./types";

export const Head = ({ title = "Proffstips", metaDescription }: HeadProps) => {
  return (
    <Helmet>
      <title>{title}</title>
      {metaDescription && <meta name="description" content={metaDescription} />}
    </Helmet>
  );
};
