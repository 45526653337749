import styled from "styled-components";
import { SiteProps } from "./types";

const StyledArticle = styled("article")`
  position: relative;
  background-color: #fff;
  border-radius: 2rem;
  overflow: hidden;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;

  & > img {
    width: 100%;
    height: 300px;

    @media (min-width: 1024px) {
      height: 200px;
    }
  }
`;

const StyledLink = styled("a")`
  width: 100%;
  text-align: center;
  padding: 1rem;

  &:hover {
    opacity: 0.8;
  }

  &:nth-of-type(1) {
    background-color: lightblue;
  }
  &:nth-of-type(2) {
    background-color: lightgreen;
  }
`;

const StyledImage = styled("img")`
  width: 100%;
  object-fit: cover;
`;

export const SiteBox = ({
  image,
  siteUrl,
  bonusUrl,
  name,
  siteText,
  bonusText,
}: SiteProps) => {
  return (
    <StyledArticle>
      <StyledImage src={image} alt={name} />
      <StyledLink href={siteUrl} target="_blank" rel="noreferrer">
        {siteText}
      </StyledLink>
      <StyledLink href={bonusUrl} target="_blank" rel="noreferrer">
        {bonusText}
      </StyledLink>
    </StyledArticle>
  );
};
