import styled from "styled-components";
import { MainWrapperProps } from "./types";
import { Head } from "src/components/Head";

const StyledMain = styled("main")`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto 1fr;
  padding: 1rem;
  max-width: 1440px;
  margin: 0 auto;
  grid-gap: 1rem;
  min-height: 100vh;

  & > section {
    grid-column: 1 / -1;
  }

  /* Grey out when mobile nav is open */
  [data-is-open="true"] ~ & {
    filter: blur(0.25rem);
  }
`;

export const MainWrapper = ({
  children,
  metaDescription,
  title,
}: MainWrapperProps) => (
  <>
    <Head title={title} metaDescription={metaDescription} />
    <StyledMain>{children}</StyledMain>
  </>
);
