import styled from "styled-components";

const StyledFooter = styled("footer")`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr auto;
  gap: 1rem;
  padding: 1rem;
  background-color: #333;
  color: #fff;
`;

const CopyRight = styled("p")`
  grid-column: 1 / -1;
  text-align: center;
`;

export const Footer = () => {
  return (
    <StyledFooter>
      <CopyRight>© 2024 ProffsTips</CopyRight>
    </StyledFooter>
  );
};
