import { MainWrapper } from "src/layouts/MainWrapper";
import bets from "src/data/bets.json";
import { useLocation } from "react-router-dom";
import { NotFound } from "./NotFound";

export const BettingTip = () => {
  const { pathname } = useLocation();
  const slug = pathname.split("/").pop();
  const bet = bets.find((bet) => bet.slug === slug);

  if (!bet) {
    return <NotFound />;
  }

  return (
    <MainWrapper
      title={`Proffstips - ${bet.match}`}
      metaDescription={bet.description}
    >
      <h1>{}</h1>
    </MainWrapper>
  );
};
