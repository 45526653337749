import styled from "styled-components";
import { BetBoxProps } from "./types";

const StyledArticle = styled("article")`
  display: grid;
  grid-template-rows: auto 1fr auto auto;
  padding: 1rem;
  border: 1px solid #000;
  border-radius: 5px;
  background-color: #f5f5f5;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

  h2 {
    border-bottom: 1px solid #000;
  }
`;

const StyledLink = styled("a")`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  background-color: lightgreen;
  border-radius: 5px;
  font-size: 1.125rem;
  font-weight: bold;

  &:hover {
    opacity: 0.8;
  }
`;

export const BetBox = ({
  match,
  // event
  description,
  odds,
  betUrl,
  date,
}: BetBoxProps) => {
  return (
    <StyledArticle>
      <h2>{match}</h2>
      <p>{description}</p>
      <p>Odds: {odds}</p>
      <p>Datum: {date}</p>
      <StyledLink href={betUrl} target="_blank" rel="noreferrer">
        Spela nu
      </StyledLink>
    </StyledArticle>
  );
};
