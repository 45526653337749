import styled from "styled-components";
import { InfoBoxProps } from "./types";

const StyledSection = styled("section")`
  padding: 1rem;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  height: fit-content;

  @media (min-width: 1024px) {
    grid-column: span 6 !important;
  }
`;

export const InfoBox = ({ children }: InfoBoxProps) => {
  return <StyledSection>{children}</StyledSection>;
};
